import React, { Component } from "react";

class SocialMedia extends React.Component {
  render() {
    return (
      <div>
        <img src="images/socialmedia.jpg" className="socialmedia" />
      </div>
    );
  }
}
export default SocialMedia;
